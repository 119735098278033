<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="logo" contain height="120" />
      </v-col>

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold ">
          <div>FabRx Ltd. Software Development Server</div>
        </h1>

        <v-container fluid>
          <v-row dense>
            <v-col
              v-for="card in cards"
              :key="card.title"
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
            >
              <v-card class="ml-4 mr-4 mb-4 mt-4" elevation="4">
                <v-row
                  ><v-col xs="12" sm="12" md="12" lg="7" cols="12"
                    ><v-card-title
                      v-text="card.title"
                      class="pa-4 "
                    ></v-card-title>
                    <v-card-text class="text-left" v-text="card.description">
                    </v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-left ml-4">
                        <a
                          :href="card.link"
                          target="_blank"
                          class="custom-href"
                        >
                          {{ card.link }}</a
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="5" cols="12">
                    <v-img
                      :src="card.src"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="270"
                      style="min-height: 250px !important; max-height: 250px !important"
                    >
                    </v-img></v-col
                ></v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="card.link != ''"
                    block
                    color="fabrxPrimary"
                    :href="card.link"
                    target="_blank"
                  >
                    Visit Website
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from "../assets/logo.png";
//import inkjet from "../assets/inkjet.png";
import m3diseen from "../assets/m3diseen.png";
import colorimetric from "../assets/colorimetric.png";

export default {
  name: "HelloWorld",

  data: () => ({
    logo,
    cards: [
      {
        title: "M3diseen Old Version",
        src: m3diseen,
        link: "https://www.m3diseen.com/predictions",
        description:
          "M3DISEEN is a web-based software application developed to accelerate 3D printing of pharmaceutical formulations using AI machine learning techniques. This software combines real-world data from hundreds of formulations to assist researchers developing new 3D printed medicines.",
      },
      {
        title: "M3diseen New Version",
        src: m3diseen,
        description:
          "M3DISEEN is a web-based software application developed to accelerate 3D printing of pharmaceutical formulations using AI machine learning techniques. This software combines real-world data from hundreds of formulations to assist researchers developing new 3D printed medicines.",

        link: "https://www.m3diseen.com/",
      },
      //{
      //  title: "Inkjet App",
      //  src: inkjet,
      //  description:
      //    "Software system for the preparation of data matrices or QR codes to meet the patient dose requirements using inkjet printing. The system allows the selection of different doses for each day and generates a medication guideline sheet. It also provides Quality Control methods including scanning tools for accessing the data matrix/QR code containing information about dose and printing process.",
      //  link: "https://www.inkjet.m3dimaker.xyz",
      //},
      {
        title: "Rapid analysis of a drug level in saliva (colorimetric method)",
        src: colorimetric,
        description:
          "Design, implementation, and validation of a system to perform a rapid analysis of a drug level in saliva using a mobile device. This analysis is performed using a colorimetric method, analyzing the image obtained with the mobile device of a sample of saliva, containing a concentration of a drug that reacts with a distinctive color of the concentration when adding the reagents.",
        link: "",
      },
    ],
  }),
};
</script>
<style scoped>
.custom-href {
  color: #bc1a2b !important;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
}

.custom-href:hover {
  cursor: pointer;
  color: #df3f4f !important;
}
</style>
